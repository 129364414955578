.inputError {
  border-color: var(--exhut-red);
  border-width: 3px;
}

.linkAsBlueButton {
  background-color: var(--exhut-blue);
  color: white;
  padding: 7px;
  border-radius: 10px;
  font-weight: 700;
  text-decoration: none;
}

.linkAsGreenButton {
  background-color: var(--exhut-green);
  color: white;
  padding: 7px;
  font-weight: 700;
  text-decoration: none;
  min-width: 70px;
  cursor: pointer;
  font-size: large;
  font-weight: bold;
}

.bigButtonGreen {
  background-color: var(--exhut-green);
  color: white;
  padding: 7px;
  text-decoration: none;
  width: 200px;
  font-weight: bold;
  font-size: large;
  border: 2px solid var(--exhut-green);
  margin: 0px;
  text-align: center;
  cursor: pointer;
}

.bigButtonRed {
  background-color: var(--exhut-light-red);
  width: 200px;
  color: white;
  padding: 7px;
  text-decoration: none;
  font-weight: bold;
  font-size: large;
  border: 2px solid var(--exhut-light-red);
  margin: 0px;
  cursor: pointer;
}

.bigButtonBlue {
  background-color: var(--exhut-blue);
  width: 200px;
  color: white;
  padding: 7px;
  text-decoration: none;
  font-weight: bold;
  font-size: large;
  border: 2px solid var(--exhut-blue);
  margin: 0px;
  cursor: pointer;
}

.backgroundless {
  color: darkslategrey;
  padding: 7px;
  text-decoration: none;
  font-weight: bold;
  background-color: inherit;
  border: 2px solid var(--exhut-light-grey0);
  margin: 0px;
  border-radius: 15px;
  cursor: pointer;
}

.buttonBlue {
  background-color: var(--exhut-blue);
  color: white;
  padding: 7px;
  text-decoration: none;
  font-weight: bold;
  border: 2px solid var(--exhut-blue);
  margin: 0px;
  border-radius: 15px;
  cursor: pointer;
}

.bigButtonGrey {
  background-color: var(--exhut-light-grey3);
  width: 200px;
  color: black;
  padding: 7px;
  text-decoration: none;
  font-weight: bold;
  font-size: large;
  border: 2px solid var(--exhut-light-grey0);
  margin: 0px;
  cursor: pointer;
}

.bigButtonGreen:hover {
  border: 2px solid var(--exhut-blue);
}

.buttonAsLink {
  color: darkblue;
  border: 0px;
  text-decoration: underline;
  cursor: pointer;
  background-color: inherit;
}

.buttonAsLink:disabled {
  color: var(--exhut-light-grey3);
}

.bigButtonGreen:disabled {
  background-color: #dadada;
  border: 0px solid #95a296;
  color: rgb(150, 149, 149);
}

button.pill {
  border-radius: 20px;
}

.blue-button {
  /* Frame 5 */

  /* Auto layout */
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  width: 80px;
  height: 24px;
  color: white;
  font-weight: bold;
  text-decoration: none;

  background: #14c8c8;
  border-radius: 70px;
}
