/* The snackbar - position it at the bottom and in the middle of the screen */
#terms-container {
  visibility: visible;
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  bottom: 10px; /* 10px from the bottom */
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#terms {
  background-color: #ef6969; /* Black background color */
  color: white; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 1em; /* Rounded borders */
  padding: 20px; /* Padding */
  z-index: 1; /* Add a z-index if needed */
  border-style: solid;
  display: flex;
}

#terms a {
  color: #80edff;
}

#terms button {
  color: white;
  background-color: #ef6969; /* Black background color */
  border: 1px white solid;
  border-radius: 5px;
  font-weight: bold;
}
