.careers-body #responsibilities strong {
  margin-right: 5px;
}

.careers-body p {
  line-height: 21px;
}

.careers-body ul {
  line-height: 21px;
}
